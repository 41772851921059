import React from "react"
import { Link } from "gatsby"

import { withCart } from "./withCart"
import { ProductGrid } from "../Sections/ProductGrid/ProductGrid"
import { CartFormInformation } from "./Form/CartFormInformation"
import { CartItem } from "./Item/CartItem"
import { CartFormTotals } from "./Form/CartFormTotals"
import { CustomerPaymentsInformationPopup } from "../Customer/Payments/Popup/CustomerPaymentsInformationPopup"
import { CartFormDiscountCode } from "./Form/CartFormDiscountCode"
import { CartFormGiftWrapping } from "./Form/CartFormGiftWrapping"
import { CartFormCheckout } from "./Form/CartFormCheckout"
import { SmallButton } from "../Styled/Button"
import { CartPage, Details, LineItems, CartRow, StyledContainer, StyledH5 } from "./CartStyles"

interface Props {
  checkout: any
  content: any
  customPaymentDescription: string
  hasLineItems: boolean
  items: Array<any>
  getGroupedItems: Function
  locales: any
  onedaypayActive: any
  products: Array<any>
  setShowPopup: Function
  settings: any
  showPopup: Function
}

export const Cart = withCart(
  ({
    checkout,
    content,
    customPaymentDescription,
    hasLineItems,
    items,
    getGroupedItems,
    locales,
    onedaypayActive,
    products,
    setShowPopup,
    settings,
    showPopup,
  }: Props) => (
    <>
      <CartPage>
        <StyledContainer count={hasLineItems}>
          <LineItems count={hasLineItems}>
            {hasLineItems ? (
              <>
                {items.map(lineItem => (
                  <CartItem key={lineItem.id.toString()} grouped={getGroupedItems(lineItem, checkout.lineItems)} lineItem={lineItem} />
                ))}

                <CartRow>
                  <CartFormGiftWrapping
                    hasMessage={checkout.note || false}
                    hasWrapping={
                      checkout?.lineItems?.find(lineItem => lineItem?.variant?.product?.handle === settings.products.giftWrappingHandle) || false
                    }
                  />
                  <CartFormDiscountCode />
                </CartRow>
              </>
            ) : (
              <>
                <StyledH5 as={`p`}>{locales.emptyTitle}</StyledH5>
                <SmallButton as={Link} to={`/`}>
                  {locales.emptyButton}
                </SmallButton>
              </>
            )}
          </LineItems>

          <Details>
            <StyledH5>{locales.details}</StyledH5>
            <CartFormTotals setShowPopup={setShowPopup} customPaymentEnabled={onedaypayActive} />
            <CartFormCheckout disabled={!hasLineItems} />

            <CartFormInformation page={content} />
          </Details>
        </StyledContainer>

        <ProductGrid items={products} title={locales.relatedTitle} />

        <CustomerPaymentsInformationPopup active={showPopup} setActive={setShowPopup} content={customPaymentDescription} />
      </CartPage>
    </>
  )
)
