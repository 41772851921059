import React, { useEffect, useRef, useState } from "react"

import { useApp } from "../../../hooks/useApp"
import { useCart } from "../../../hooks/useCart"
import { useCheckout } from "../../../hooks/useCheckout"
import { useLanguage } from "../../../hooks/useLanguage"
import { useLocation } from "../../../hooks/useLocation"
import { useShopify } from "../../../hooks/useShopify"

export const withCartFormGiftWrapping = Component => ({ name = `CartFormGiftWrapping`, hasMessage, hasWrapping }) => {
  const [active, setActive] = useState(false)
  const [adding, setAdding] = useState(false)
  const [changed, setChanged] = useState(false)
  const [message, setMessage] = useState(hasMessage)
  const [wrapping, setWrapping] = useState(hasWrapping ? true : false)
  const [isWholesale, setIsWholesale] = useState(false)
  const {
    config: { settings },
  } = useApp()
  const { updateAttributes } = useCheckout()
  const { addToCart, removeFromCart } = useCart()
  const { useProducts } = useShopify()
  const giftWrapping = useProducts({ handles: [settings.products.giftWrappingHandle], firstVariants: 1 })
  const field = useRef()
  const locales = useLanguage(`cart`)
  const { storeConfig } = useLocation()

  const handleGiftWrapping = async () => {
    const note = message && wrapping ? `${locales.giftMessagePrefix} ${message}` : ``
    setAdding(true)

    if (wrapping && !hasWrapping) {
      await addToCart(giftWrapping[0].variants[0].id, 1)
      await updateAttributes({ note })
      setAdding(false)
      setChanged(false)
    } else if (!wrapping && hasWrapping) {
      await removeFromCart(hasWrapping.variant.id)
      await updateAttributes({ note })
      setAdding(false)
      setChanged(false)
    } else {
      await updateAttributes({ note })
      setAdding(false)
      setChanged(false)
    }
  }

  useEffect(() => {
    if(storeConfig.wholesale ) {
      setIsWholesale(true)
    }
  }, [storeConfig, isWholesale])

  useEffect(() => {
    if (!changed && active && !changed) {
      setChanged(true)
    } else if (changed && hasMessage === message && (hasWrapping !== false) === wrapping) {
      setChanged(false)
    }
  }, [message, wrapping])

  useEffect(() => {
    if (wrapping) {
      field?.current?.select()
    }
  }, [wrapping])

  Component.displayName = name
  return (
    <Component
      active={false}
      adding={adding}
      changed={changed}
      field={field}
      giftWrapping={false}
      handleGiftWrapping={handleGiftWrapping}
      hasWrapping={hasWrapping}
      isWholesale={isWholesale}
      locales={locales}
      message={message}
      setActive={false}
      setMessage={setMessage}
      setWrapping={setWrapping}
      wrapping={wrapping}
    />
  )
}
