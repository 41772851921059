import React from "react"

import { withCartFormGiftWrapping } from "./withCartFormGiftWrapping"
import { Popup } from "../../Modals/Popup/Popup"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
import { Money } from "../../Money"
import { P } from "../../Styled/Text"
import {
  Content,
  WrappingForm,
  IconPlus,
  WrappingH5,
  WrappingP,
  WrappingPrimaryButton,
  WrappingTextarea,
  Underline,
  WrappingButton,
} from "../CartStyles"

interface Props {
  active: boolean
  adding: boolean
  changed: boolean
  field: any
  giftWrapping: boolean
  handleGiftWrapping: Function
  hasMessage: boolean
  hasWrapping: boolean
  locales: any
  message: string
  setActive: Function
  setMessage: Function
  setWrapping: Function
  wrapping: boolean
}

export const CartFormGiftWrapping = withCartFormGiftWrapping(
  ({
    active,
    adding,
    changed,
    field,
    giftWrapping,
    handleGiftWrapping,
    hasWrapping,
    isWholesale,
    locales,
    message,
    setActive,
    setMessage,
    setWrapping,
    wrapping,
  }: Props) => (
    !isWholesale && (
      <>
      <WrappingButton onClick={() => setActive(true)}>
        <IconPlus />
        <P>{hasWrapping ? locales.giftTitleWrapping : locales.giftTitle}</P>
        <Underline>{hasWrapping ? locales.giftMessageWrapping : locales.giftMessage}</Underline>
      </WrappingButton>

      <Popup active={active} setActive={setActive}>
        <Content>
          <WrappingH5>{locales.giftPopupTitle}</WrappingH5>
          <WrappingP>{locales.giftPopupMessage}</WrappingP>

          <WrappingForm>
            {giftWrapping?.[0] && (
              <Checkbox checked={hasWrapping} onChange={checkbox => setWrapping(checkbox.checked)}>
                {`${locales.giftAddButton} ${giftWrapping[0]?.title?.toLowerCase()}`}{" "}
                <Money amount={giftWrapping[0]?.variants?.[0]?.priceV2} simple />
              </Checkbox>
            )}
            <WrappingTextarea
              ref={field}
              placeholder={locales.giftPlaceholder}
              onChange={event => setMessage(event.target.value)}
              hidden={!wrapping}
              maxLength={120}
              value={message ? message.replace(`${locales.giftMessagePrefix} `, ``) : ``}
            />
            <WrappingPrimaryButton disabled={adding} full onClick={changed ? handleGiftWrapping : () => setActive(false)}>
              {adding ? locales.giftSavingButton : changed ? locales.giftSaveButton : locales.giftDoneButton}
            </WrappingPrimaryButton>
          </WrappingForm>
        </Content>
      </Popup>
    </>
    )
  )
)
