import React from "react"

import { withCartFormDiscountCode } from "./withCartFormDiscountCode"
import { FormError } from "../../Styled/Form"
import { Form, StyledP, StyledInput, StyledSmallButton, Wrapper } from "../CartStyles"

export const CartFormDiscountCode = withCartFormDiscountCode(({ discountCode, error, handleSubmit, locales, setCode }) => (
  <Wrapper>
    <Form onSubmit={handleSubmit}>
      <StyledP>{locales.discountsTitle}</StyledP>
      <StyledInput
        errors={error ? `true` : null}
        onChange={({ target: { value } }) => setCode(value)}
        name={`discountCode`}
        placeholder={locales.discountsPlaceholder}
        value={discountCode}
      />
      <StyledSmallButton type={`submit`}>{locales.discountsButton}</StyledSmallButton>
    </Form>

    {error ? <FormError>{error}</FormError> : null}
  </Wrapper>
))
