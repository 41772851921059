import React, { useState } from "react"

import { useCheckout } from "../../../hooks/useCheckout"
import { useLanguage } from "../../../hooks/useLanguage"

export const withCartFormDiscountCode = Component => ({ name = `CartFormDiscountCode` }) => {
  const [error, setError] = useState(null)
  const [discountCode, setCode] = useState(``)
  const { applyDiscountCode } = useCheckout()
  const locales = useLanguage(`cart`)

  const handleSubmit = async e => {
    e.preventDefault()

    setError(null)
    const discounts = await applyDiscountCode(discountCode)
    setCode(``)

    if (discounts?.checkoutUserErrors?.[0]?.message) {
      setError(discounts.checkoutUserErrors[0].message.replace(`Discount code `, ``))
    }
  }

  Component.displayName = name
  return <Component discountCode={discountCode} error={error} handleSubmit={handleSubmit} locales={locales} setCode={setCode} />
}
